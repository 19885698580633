.page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../data/img/welcome-background-agri.jpg');
    background-size: cover;
    opacity: 0.2;
    z-index: -2;
}

.form-container {
    display: flex;
    flex-direction: column;
    background-color: var(--unsupported-blurry);
    padding: 2rem;
    border-radius: var(--containers-radius);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.form-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.form-title-container img {
    width: 6rem;
    height: auto;
}

.logo-demetra {
    margin-top: 2rem;
    border-radius: 50%;
}

.form-title-container h1 {
    color: var(--cat-yellow);
}

.form-title-container span {
    color: white;
}

.form-title-container h4 {
    color: var(--active-icon);
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    align-items: center;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.icon {
    color: white;
    border-radius: var(--round);
    padding: var(--round-icon-padding);
}

input {
    padding: var(--input-padding);
    border: none;
    border-radius: var(--element-radius);
    width: 100%;
    color: white;
    transition: all 0.2s;
}

input:hover {
    opacity: var(--button-opacity);
}

input:focus {
    outline: none;
    color: black;
    background-color: var(--cat-yellow);
    padding: var(--input-focus-padding);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--inactive);
    opacity: 1;
    /* Firefox */
}

input:focus::placeholder {
    color: transparent;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--inactive);
}

input:focus::-ms-input-placeholder {
    color: transparent;
}

button {
    margin-top: 2rem;
    padding: var(--button-padding);
    background-color: var(--cat-yellow);
    color: black;
    border: none;
    border-radius: var(--element-radius);
    cursor: pointer;
    transition: all 0.2s;
}

button:hover {
    opacity: var(--button-opacity);
}

button:focus {
    outline: none;
}

.error-message {
    color: var(--alert-icon);
    font-size: small;
}

/**************************************************************/
/********************* Global and support *********************/
/**************************************************************/

/* In case some browser support transparent blur*/
@supports (backdrop-filter: blur(1rem)) {
    input,
    .icon,
    .form-container {
        background: var(--blurry);
        backdrop-filter: blur(0.75rem);
    }
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

@media (max-width: 768px) {
    input {
        font-size: large
    }

    .form-title-container {
        font-size: medium;
    }

    .form-title-container img {
        width: 4rem;
    }
}
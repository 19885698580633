.multiline-chart-container {
    padding: 1rem;
    width: 100%;
    height: 100%;
}

.custom-tooltip {
    padding: 1rem;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.timestamp {
    color: white;
}

.custom-tooltip p {
    margin: 0;
    font-size: small;
}
.edit-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    height: 37rem;
    overflow: hidden;
}

.scrollable-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 8px 0 0 0;
    padding: 4px;
    height: auto;
    overflow-y: scroll;
}

.edit-container h2 {
    color: var(--cat-yellow);
    margin: var(--paragraph-margin)
}

.edit-container h3 {
    color: white;
    overflow: hidden;
    margin: var(--paragraph-margin)
}

.edit-container p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    overflow: hidden;
    font-size: small;
    margin: var(--paragraph-margin)
}

.edit-container span {
    color: var(--cat-yellow)
}

.edit-property-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    margin: 1rem 0 0 0;
    color: var(--cat-yellow);
    font-size: small;
}

.edit-property-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin: 0.2rem;
    color: white;
    font-size: small;
}

.edit-property-container-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin: 0.2rem;
    color: white;
    font-size: small;
}

.edit-property-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
}

.edit-property-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    width: 	65%;
}

.edit-property-value .edit-property-error {
    color: var(--alert-icon);
}

.edit-property-icon {
    min-width: 0.8rem;
    margin: 0;
    color: white;
}

input[type="checkbox"] { 
    height: 22px;
    accent-color:  var(--cat-yellow);
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

/* Mobile phones */
@media (max-width: 768px) {
    .edit-container {
        height: 35rem;
    }
}

/* Small desktop */
@media (max-height: 750px) {
    .edit-container {
        height: 26rem;
    }
}

/* Small phones */
@media (max-height: 720px) {
    .edit-container {
        height: 30rem;
    }
}

/* X-small mobile phones */
@media (max-width: 380px) {
    .edit-container {
        height: 25rem;
    }
}
/* PaginationDots.module.css */

.dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot-active {
    width: 30px;
    height: 30px;
    color: var(--cat-yellow);
    cursor: pointer;
}

.dot-inactive {
    width: 20px;
    height: 20px;
    color: var(--inactive);
    cursor: pointer;
}
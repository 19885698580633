.user-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 50px;
  right: 25px;
  color: white;
  background-color: var(--unsupported-blurry);
  border-radius: var(--containers-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.4rem;
  z-index: 2000;
  overflow: auto;
  transition: all 0.2s;
  animation: fadeIn 0.2s
}

.close-button {
  width: 0px;
  height: 0px;
  border-radius: var(--round);
  padding: 0;
  transition: all 0.2s ease-in-out;
  visibility: collapse;
}

.close-button:hover {
  background-color: var(--blurry);
}

.user-title {
  color: var(--cat-yellow);
  margin: 0;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.user-panel button {
  margin: 0;
  margin-top: 2rem;
}

.avatar-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--blurry);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.avatar-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.initials {
  font-weight: bold;
  color: var(--cat-yellow);
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

@media (max-width: 768px) {
  .user-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }

  .user-title {
    margin-bottom: 1rem;
  }

  .close-button {
    width: 20px;
    height: 20px;
    border-radius: var(--round);
    padding: var(--round-icon-padding);
    transition: all 0.2s ease-in-out;
    visibility: visible;
  }
}

/**************************************************************/
/************************* Animations *************************/
/**************************************************************/

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

/**************************************************************/
/********************* Global and support *********************/
/**************************************************************/

/* In case some browser support transparent blur*/
@supports (backdrop-filter: blur(1rem)) {
  .user-panel {
    background: var(--blurry);
    backdrop-filter: blur(0.75rem);
  }
}
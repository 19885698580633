.weather-panel-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 50px;
    left: 25px;
    color: white;
    background-color: var(--unsupported-blurry);
    border-radius: var(--containers-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0.4rem;
    z-index: 2000;
    overflow: auto;
    transition: all 0.2s;
    animation: fadeIn 0.2s;
}

.close-button {
    width: 0px;
    height: 0px;
    border-radius: var(--round);
    padding: 0;
    transition: all 0.2s ease-in-out;
    visibility: collapse;
}

.close-button:hover {
    background-color: var(--blurry);
}

.weather-title {
    color: var(--cat-yellow);
    margin: 0;
    margin-bottom: 0.2rem;
}

.weather-icon {
    visibility: collapse;
    width: 0px;
    height: 0px;
}

.weather-info-container {
    display: flex;
    gap: 2rem;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}


.column p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    overflow: hidden;
    font-size: small;
    margin: var(--paragraph-margin)
}

.column span {
    color: var(--cat-yellow)
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

@media (max-width: 768px) {
    .weather-panel-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: white;
    }

    .weather-title {
        margin-bottom: 1rem;
    }

    .weather-icon {
        visibility: visible;
        width: 80px;
        height: 80px;
    }

    .close-button {
        width: 20px;
        height: 20px;
        border-radius: var(--round);
        padding: var(--round-icon-padding);
        transition: all 0.2s ease-in-out;
        visibility: visible;
    }
}

/**************************************************************/
/************************* Animations *************************/
/**************************************************************/

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/**************************************************************/
/********************* Global and support *********************/
/**************************************************************/

/* In case some browser support transparent blur*/
@supports (backdrop-filter: blur(1rem)) {
    .weather-panel-container {
        background: var(--blurry);
        backdrop-filter: blur(0.75rem);
    }
}
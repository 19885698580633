@import url('https://cdn.syncfusion.com/ej2/material.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

/**************************************************************/
/************************** Variables *************************/
/**************************************************************/

:root {
  /* Colors */
  --unsupported-blurry: hsla(0, 0%, 0%, 0.75);
  --blurry: hsla(0, 0%, 100%, 0.102);
  --main-background: #2b2b2b;
  --cat-yellow: #ffc400;
  --black: #0F0F0F;
  --active-icon: #adff2f;
  --alert-icon: #ff2f2f;
  --inactive: #a5a5a5;

  /* Opacities */
  --button-opacity: 80%;

  /* Radius */
  --containers-radius: 0.5rem;
  --element-radius: 0.2rem;
  --round: 50%;

  /* Padding */
  --input-padding: 0.5rem;
  --input-focus-padding: 1rem 0.5rem;
  --button-padding: 0.5rem 20px;
  --round-icon-padding: 0.5rem;

  /* Margins */
  --paragraph-margin: 5px;
}

/**************************************************************/
/************************* Global zone ************************/
/**************************************************************/

html {
  background-color: var(--main-background);
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
  touch-action: pan-x pan-y;
  user-select: none;
  -webkit-user-select: none;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

* {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  touch-action: manipulation;
}

@viewport {
  zoom: 1.0;
  width: device-width;
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}
.loading-container {
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.loading-container h1 {
    font-size: medium;
}
.notifications-panel-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 50px;
  right: 65px;
  color: white;
  background-color: var(--unsupported-blurry);
  border-radius: var(--containers-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.4rem;
  z-index: 2000;
  overflow: auto;
  transition: all 0.2s;
  animation: fadeIn 0.2s
}

.close-button {
  width: 0px;
  height: 0px;
  border-radius: var(--round);
  padding: 0;
  transition: all 0.2s ease-in-out;
  visibility: collapse;
}

.close-button:hover {
  background-color: var(--blurry);
}

.notifications-title {
  color: var(--cat-yellow);
  margin: 0;
  margin-bottom: 0.2rem;
}

.notifications-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 0.4rem;
}

.no-notifications{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  opacity: 0.5;
}

.notification {
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  justify-content: flex-start;
}

.notification-icon {
  width: 30px;
  height: 30px;
}

.notification-data {
  display: flex;
  flex-direction: column;
}

.notification-data p {
  font-size: smaller;
  margin: 0px;
}

.notification-data .notification-title {
  color: var(--cat-yellow)
}

.notification-data .notification-timestamp {
  opacity: 0.5;
}

.notification:hover {
  border-radius: var(--containers-radius);
  background-color: var(--blurry);
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

@media (max-width: 768px) {
  .notifications-panel-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }

  .notifications-title {
    margin-bottom: 1rem;
  }

  .close-button {
    width: 20px;
    height: 20px;
    border-radius: var(--round);
    padding: var(--round-icon-padding);
    transition: all 0.2s ease-in-out;
    visibility: visible;
  }
}

/**************************************************************/
/************************* Animations *************************/
/**************************************************************/

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

/**************************************************************/
/********************* Global and support *********************/
/**************************************************************/

/* In case some browser support transparent blur*/
@supports (backdrop-filter: blur(1rem)) {
  .notifications-panel-container {
    background: var(--blurry);
    backdrop-filter: blur(0.75rem);
  }
}
.page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    gap: 2rem;
    height: 100%;
    width: 100%;
}

.toast-container {
    margin-top: 0.2rem;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../data/img/welcome-background-agri.jpg');
    background-size: cover;
    opacity: 0.2;
    z-index: -2;
}

.navbar-container {
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-data-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
    border-radius: var(--containers-radius);
}

.navbar-data-container:hover {
    background-color: var(--blurry);
}

.navbar-container h1 {
    font-size: larger;
    color: var(--cat-yellow);
    margin: var(--paragraph-margin);
}

.offline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-height: 2rem;
    width: 100%;
    padding: 0.2rem;
    gap: 0.2rem;
    background-color: var(--cat-yellow);
}

.offline-container-icon {
    height: 20px;
    width: 20px;
}

.offline-container h2 {
    color: black;
}


.navbar-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    margin-right: 1rem;
}

.notification-icon-container {
    position: relative;
}

.notification-dot {
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: var(--cat-yellow);
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.weather-icon {
    width: 40px;
    height: 40px;
}

.navbar-icon {
    color: white;
    border-radius: var(--round);
    padding: var(--round-icon-padding);
    transition: all 0.2s ease-in-out;
}

.navbar-icon:hover {
    background-color: var(--blurry);
}

.silos-list-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 0.2s;
}

.page-enter {
    opacity: 0;
    height: 0;
    transform: translateX(100%);
}

.page-enter-active {
    opacity: 1;
    height: auto;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    height: auto;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0;
    height: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}

.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 100%;
    width: 100%;
}

.no-data-container h2 {
    color: var(--cat-yellow);
    margin: var(--paragraph-margin);
}

.no-data-container img {
    width: 200px;
    height: 200px;
}

.no-data-container p {
    color: white;
    margin: var(--paragraph-margin);
}

.no-data-container a {
    color: var(--cat-yellow);
}

.no-data-container h2,
.no-data-container a,
.no-data-container p {
    text-align: center;
}

.silos-page {
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
}

.stats-container {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    font-size: small;
    justify-content: space-around;
}

.stats-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.stats-data h3 {
    border-radius: var(--containers-radius);
    color: var(--cat-yellow);
    margin: var(--paragraph-margin);
    margin-right: 0.5rem;
}

.stats-data p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--inactive);
    margin: var(--paragraph-margin);
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-container p {
    font-size: x-small;
    color: var(--inactive);
}

/**************************************************************/
/************************* Responsive *************************/
/**************************************************************/

@media (max-height: 900px) {
    .page-container {
        gap: 1rem;
    }

    .navbar-data-container h1 {
        font-size: large
    }

    .stats-container {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .page-container {
        gap: 0.5rem;
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../data/img/welcome-background-agri-mobile.jpg');
        background-size: cover;
        opacity: 0.2;
        z-index: -2;
    }

    /* .background-image {
        visibility: collapse;
    } */

    .navbar-container {
        background-color: var(--blurry);
    }

    .navbar-container h1 {
        font-size: medium;
    }

    .silos-container {
        flex-direction: column;
        align-items: center;
    }

    .stats-container {
        visibility: collapse;
    }

    .stats-data h3 {
        padding: 0;
        font-size: small;
    }

    .footer-container {
        height: 0;
        visibility: collapse;
    }
}